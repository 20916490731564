export const ADMIN_ROUTE = '/admin'
export const LOGIN_ROUTE = '/login'
export const REGISTRATION_ROUTE = '/registration'
export const ROUTE_3D = '/3d'

export const MODEL_ROUTE_3D_CLEAR = '/clearmodel'
export const MODEL_ROUTE_3D_CLEAR_TEXT = '/cleartext'

export const ROLE_ROUTE = '/role'
export const MODEL_ROUTE = '/model'
export const MODEL_ROUTE_3D = '/model3d'
export const PHOTO_ROUTE = '/photo'
export const VIDEO_ROUTE = '/video'
export const AUDIO_ROUTE = '/audio'
export const TEXT_ROUTE = '/text'

export const PRIVACY_ROUTE = '/privacy'
export const TOS_ROUTE = '/tos'
export const TOS_COOKIE_ROUTE = '/cookie'
export const HOME_ROUTE = ''
