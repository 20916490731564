import { $authHost, $host } from '../http/index'

/** Manufacturer ******************************************* */
export const fetchManufacturerCreate = async obj => {
  const { data } = await $authHost.post('api/manufacturer/create/', obj)
  return data
}
export const fetchManufacturer = async (id, name, page = 1, limit = 5) => {
  const { data } = await $host.get('api/manufacturer', { params: { id, name, page, limit } })
  return data
}
export const fetchManufacturerUpdate = async obj => {
  const { data } = await $authHost.post('api/manufacturer/update/' + obj.id, obj)
  return data
}
export const fetchOneManufacturer = async id => {
  const { data } = await $host.get('api/manufacturer/' + id)
  return data
}
export const fetchManufacturerDelete = async id => {
  const { data } = await $authHost.get('api/manufacturer/delete/' + id)
  return data
}

/** Model ******************************************* */
export const fetchModelCreate = async obj => {
  const { data } = await $authHost.post('api/racktype/create/', obj)
  return data
}
export const fetchModel = async ({
  id,
  name,
  manufacturer,
  model3d,
  type,
  color,
  params1,
  params2,
  user,
  page = 1,
  limit = 5,
}) => {
  const { data } = await $host.get('api/racktype', {
    params: { id, name, manufacturer, model3d, type, color, params1, params2, user, page, limit },
  })
  return data
}
export const fetchModelUpdate = async obj => {
  const { data } = await $authHost.post('api/racktype/update/' + obj.id, obj)
  return data
}
export const fetchModelUploadGLB = async (obj, id) => {
  const { data } = await $authHost.post('api/racktype/uploadglbjpg/' + id, obj)
  return data
}
export const fetchOneModel = async id => {
  const { data } = await $host.get('api/racktype/' + id)
  return data
}
export const fetchModelDelete = async id => {
  const { data } = await $authHost.get('api/racktype/delete/' + id)
  return data
}

/** Text *******************************************/
export const fetchTextCreate = async obj => {
  const { data } = await $authHost.post('api/text/create/', obj)
  return data
}
export const fetchText = async ({
  id,
  name,
  manufacturer,
  model3d,
  color,
  params1,
  params2,
  user,
  page = 1,
  limit = 5,
}) => {
  const { data } = await $host.get('api/text', {
    params: { id, name, manufacturer, model3d, color, params1, params2, user, page, limit },
  })
  return data
}
export const fetchTextUpdate = async obj => {
  const { data } = await $authHost.post('api/text/update/' + obj.id, obj)
  return data
}
export const fetchTextUploadJPG = async (obj, id) => {
  const { data } = await $authHost.post('api/text/upload_jpg/' + id, obj)
  return data
}
export const fetchTextUploadMP4 = async (obj, id) => {
  const { data } = await $authHost.post('api/text/upload_mp4/' + id, obj)
  return data
}
export const fetchOneText = async id => {
  const { data } = await $host.get('api/text/' + id)
  return data
}
export const fetchTextDelete = async id => {
  const { data } = await $authHost.get('api/text/delete/' + id)
  return data
}
/** Video ******************************************* */

export const fetchVideoCreate = async obj => {
  const { data } = await $authHost.post('api/video/create/', obj)
  return data
}
export const fetchVideo = async ({
  id,
  name,
  manufacturer,
  model3d,
  color,
  params1,
  params2,
  user,
  page = 1,
  limit = 5,
}) => {
  const { data } = await $host.get('api/video', {
    params: { id, name, manufacturer, model3d, color, params1, params2, user, page, limit },
  })
  return data
}
export const fetchVideoUpdate = async obj => {
  const { data } = await $authHost.post('api/video/update/' + obj.id, obj)
  return data
}
export const fetchVideoUploadJPG = async (obj, id) => {
  const { data } = await $authHost.post('api/video/upload_jpg/' + id, obj)
  return data
}
export const fetchVideoUploadMP4 = async (obj, id) => {
  const { data } = await $authHost.post('api/video/upload_mp4/' + id, obj)
  return data
}

export const fetchOneVideo = async id => {
  const { data } = await $host.get('api/video/' + id)
  return data
}
export const fetchVideoDelete = async id => {
  const { data } = await $authHost.get('api/video/delete/' + id)
  return data
}
/** Photo ******************************************* */
export const fetchPhotoCreate = async obj => {
  const { data } = await $authHost.post('api/photo/create/', obj)
  return data
}
export const fetchPhoto = async ({
  id,
  name,
  manufacturer,
  model3d,
  color,
  params1,
  params2,
  user,
  page = 1,
  limit = 5,
}) => {
  const { data } = await $host.get('api/photo', {
    params: { id, name, manufacturer, model3d, color, params1, params2, user, page, limit },
  })
  return data
}
export const fetchPhotoUpdate = async obj => {
  const { data } = await $authHost.post('api/photo/update/' + obj.id, obj)
  return data
}
export const fetchPhotoUploadJPG = async (obj, id) => {
  const { data } = await $authHost.post('api/photo/upload_jpg/' + id, obj)
  return data
}
export const fetchPhotoUploadMP4 = async (obj, id) => {
  const { data } = await $authHost.post('api/photo/upload_mp4/' + id, obj)
  return data
}
export const fetchOnePhoto = async id => {
  const { data } = await $host.get('api/photo/' + id)
  return data
}
export const fetchPhotoDelete = async id => {
  const { data } = await $authHost.get('api/photo/delete/' + id)
  return data
}
/** Audio ******************************************* */
export const fetchAudioCreate = async obj => {
  const { data } = await $authHost.post('api/audio/create/', obj)
  return data
}
export const fetchAudio = async ({
  id,
  name,
  manufacturer,
  model3d,
  color,
  params1,
  params2,
  user,
  page = 1,
  limit = 5,
}) => {
  const { data } = await $host.get('api/audio', {
    params: { id, name, manufacturer, model3d, color, params1, params2, user, page, limit },
  })
  return data
}
export const fetchAudioUpdate = async obj => {
  const { data } = await $authHost.post('api/audio/update/' + obj.id, obj)
  return data
}
export const fetchAudioUploadJPG = async (obj, id) => {
  const { data } = await $authHost.post('api/audio/upload_jpg/' + id, obj)
  return data
}
export const fetchAudioUploadMP3 = async (obj, id) => {
  const { data } = await $authHost.post('api/audio/upload_mp3/' + id, obj)
  return data
}
export const fetchOneAudio = async id => {
  const { data } = await $host.get('api/audio/' + id)
  return data
}
export const fetchAudioDelete = async id => {
  const { data } = await $authHost.get('api/audio/delete/' + id)
  return data
}

/** DC ******************************************* */
export const fetchDCCreate = async obj => {
  const { data } = await $host.post('api/dc/create/', obj)
  return data
}
export const fetchDC = async (id, name, adress, model3d, color, page = 1, limit = 5) => {
  const { data } = await $host.get('api/dc', { params: { id, name, adress, model3d, color, page, limit } })
  return data
}
export const fetchDCUpdate = async obj => {
  const { data } = await $host.post('api/dc/update/' + obj.id, obj)
  return data
}
export const fetchOneDC = async id => {
  const { data } = await $host.get('api/dc/' + id)
  return data
}
export const fetchDCDelete = async id => {
  const { data } = await $host.get('api/dc/delete/' + id)
  return data
}
export const fetchDCUploadJPG = async (obj, id) => {
  const { data } = await $authHost.post('api/dc/upload_jpg/' + id, obj)
  return data
}
/**  ******************************************* */

/**  ******************************************* */
/**  ******************************************* */
/**  ******************************************* */
