import { makeAutoObservable } from 'mobx'
import { PROGRESS_TYPES } from 'constants/progressTypes'

export default class StoreVideo {
  constructor() {
    this._video = []
    this._video3d = []
    this._videoOne = {}
    this._videoPage = 1
    this._videoTotal = 0
    this._videoLimit = 5
    this._progressPage = PROGRESS_TYPES.WORK
    this._progress = PROGRESS_TYPES.WORK

    makeAutoObservable(this)
  }

  /**** Video ************************************************************************ */
  setVideo(obj) {
    this._video = obj
  }
  get getVideo() {
    return this._video
  }

  setVideoOne(obj) {
    this._videoOne = obj
  }
  get getVideoOne() {
    return this._videoOne
  }
  setVideoPage(page) {
    this._videoPage = page
  }
  get getVideoPage() {
    return this._videoPage
  }
  setVideoTotal(total) {
    this._videoTotal = total
  }
  get getVideoTotal() {
    return this._videoTotal
  }
  get getVideoLimit() {
    return this._videoLimit
  }

  setProgress(obj) {
    this._progress = obj
  }
  get getProgress() {
    return this._progress
  }
  setProgressPage(obj) {
    this._progressPage = obj
  }
  get getProgressPage() {
    return this._progressPage
  }

  /**************************************************************************************** */
}
