import { makeAutoObservable } from 'mobx'
import { PROGRESS_TYPES } from 'constants/progressTypes'

export default class StoreAudio {
  constructor() {
    this._audio = []
    this._audio3d = []
    this._audioOne = {}
    this._audioPage = 1
    this._audioTotal = 0
    this._audioLimit = 7
    this._progressPage = PROGRESS_TYPES.WORK
    this._progress = PROGRESS_TYPES.WORK

    makeAutoObservable(this)
  }

  /**** Audio ************************************************************************ */
  setAudio(obj) {
    this._audio = obj
  }
  get getAudio() {
    return this._audio
  }

  setAudioOne(obj) {
    this._audioOne = obj
  }
  get getAudioOne() {
    return this._audioOne
  }
  setAudioPage(page) {
    this._audioPage = page
  }
  get getAudioPage() {
    return this._audioPage
  }
  setAudioTotal(total) {
    this._audioTotal = total
  }
  get getAudioTotal() {
    return this._audioTotal
  }
  get getAudioLimit() {
    return this._audioLimit
  }

  setProgress(obj) {
    this._progress = obj
  }
  get getProgress() {
    return this._progress
  }
  setProgressPage(obj) {
    this._progressPage = obj
  }
  get getProgressPage() {
    return this._progressPage
  }

  /**************************************************************************************** */
}
