import React, { useRef, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { fetchTextCreate } from 'http/commAPI'
import { TEXT_ROUTE } from 'utils/consts'
import { i3d_glob } from '3d/dev2020/i3d_glob.js'
import { marked } from 'marked'
import stylesUi from 'ui/Ui.module.scss'
import { Context } from 'index'
import { observer } from 'mobx-react-lite'

import Section from 'ui/InputLabel/Section'
import Button from 'ui/Button/Button'

const TextComponent = React.lazy(() => import('components/Text/Text'))

const Text = observer(() => {
  const history = useHistory()
  const textDiv = useRef<HTMLDivElement>(null)
  const { storeText } = useContext(Context)

  async function CREATE(event) {
    const data = await fetchTextCreate({ name: '', params2: '', params3: '' })
    history.push(TEXT_ROUTE + '/' + data.id)
  }
  //console.log('i3d_glob.width=', i3d_glob.width)
  //console.log('storeText?.getTxt=', storeText?.getTxt)
  return (
    <>
      <div
        ref={textDiv}
        style={{
          position: 'absolute',
          top: '10px',
          left: `${i3d_glob.width + 20}px`,
          zIndex: 99999999,
          right: '20px',
          height: `${window.innerHeight - 40}px`,
          border: '0px solid #f00',
          overflowY: 'scroll',
        }}
      >
        {storeText?.getTxt ? (
          <div className={stylesUi.marked} dangerouslySetInnerHTML={{ __html: marked.parse(storeText.getTxt) }}></div>
        ) : (
          <></>
        )}
      </div>
      {/* <Section title={'Text list'}>
        <TextComponent />

        <Button onClick={e => CREATE(e)}>CREATE</Button>
      </Section> */}
    </>
  )
})

export default Text
