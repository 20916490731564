import React, { useContext, Suspense } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { authRoutes, publicRoutes } from './routes'
import { HOME_ROUTE } from './utils/consts'
import { Context } from './index'
import { observer } from 'mobx-react-lite'
import queryString from 'query-string'
import { PROGRESS_TYPES } from 'constants/progressTypes'
import { i3d_glob } from '3d/dev2020/i3d_glob.js'
import ElementData from 'components/ElementData/ElementData'
import TextField from 'pages/Text/TextField'

const ArrowToggle = React.lazy(() => import('components/sidebar01/arrowToggle'))
const SideBar = React.lazy(() => import('components/sidebar01/sidebar01'))
const ThreeMount2 = React.lazy(() => import('3d/three-mount-2-f'))
const Loader = React.lazy(() => import('ui/Loader/Loader'))
// const ElementData = React.lazy(() => import('components/ElementData/ElementData'))

const AppRouter = observer(props => {
  const { user, storeModel, device, store } = useContext(Context)
  const parsed = queryString.parse(window.location.search)

  const path = window.location.pathname

  if (parsed?.width) {
    i3d_glob.width = Number(parsed.width) || window.innerWidth // i3d_glob.width - размер окна сцены
  }
  if (parsed?.height) {
    i3d_glob.height = Number(parsed.height) || window.innerHeight
  }
  if (!parsed?.color) {
    parsed.color = 'FFF'
  }
  if (parsed?.scolor) {
    i3d_glob.scolor = '#' + parsed.scolor
  }
  if (parsed?.opacity !== undefined) {
    i3d_glob.opacity = Number(parsed.opacity)
  }
  if (parsed?.r !== undefined) {
    i3d_glob.rgbSelect = true
    i3d_glob.rgb.r = Number(parsed.r)
  }
  if (parsed?.g !== undefined) {
    i3d_glob.rgbSelect = true
    i3d_glob.rgb.g = Number(parsed.g)
  }
  if (parsed?.b !== undefined) {
    i3d_glob.rgbSelect = true
    i3d_glob.rgb.b = Number(parsed.b)
  }
  // if (parsed?.width && parsed?.height) {
  //   i3d_glob.parentIFrameWidth = parsed.width
  //   i3d_glob.parentIFrameHeight = parsed.height
  // }

  const arrowToggle = () => {
    // const path = window.location.pathname
    if (path?.includes('clear')) {
      return <></>
    } else {
      return <ArrowToggle />
    }
  }
  const sideBar = () => {
    // const path = window.location.pathname
    if (path?.includes('clear')) {
      return <></>
    } else {
      return <SideBar />
    }
  }

  const textField = () => {
    if (path?.includes('text')) {
      if (!i3d_glob.width) {
        i3d_glob.width = 200 // i3d_glob.width - размер окна сцены
      }
      return <TextField />
    } else {
      return <></>
    }
  }

  if (!i3d_glob.width) {
    if (path?.includes('text')) {
      i3d_glob.width = 200 // i3d_glob.width - размер окна сцены
    } else {
      i3d_glob.width = window.innerWidth
    }
  }

  return (
    <Suspense fallback={<Loader />}>
      <div className="d-flex">
        {storeModel.getProgress3d === PROGRESS_TYPES.WORK || storeModel.getProgress3d === PROGRESS_TYPES.UPDATE ? (
          <div
            id="contentLoader"
            style={{
              position: 'absolute',
              zIndex: 50,
              display: 'flex',
              top: '50%',
              // left: '50%',
              left: `${i3d_glob.width / 2}px`,
              width: '70px',
              height: '70px',
              marginTop: '-35px',
              marginLeft: '-35px',
            }}
          >
            <Loader progress={device.loadingProgress} main={true} />
          </div>
        ) : (
          <></>
        )}
        {/* </div> */}

        {arrowToggle()}
        {sideBar()}
        {textField()}

        {i3d_glob.updateMoving ? <ElementData /> : <></>}

        <div id="content" className="">
          <ThreeMount2 color={parsed.color} />
          <Switch>
            {user.isAuth &&
              authRoutes.map(({ path, Component }) => <Route key={path} path={path} component={Component} exact />)}
            {publicRoutes.map(({ path, Component }) => (
              <Route key={path} path={path} component={Component} exact />
            ))}
            <Redirect to={HOME_ROUTE} />
          </Switch>
        </div>
      </div>
    </Suspense>
  )
})

export default AppRouter
