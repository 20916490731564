import React, { useContext, useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import AppRouter from './AppRouter'

import './styles/index.scss'

import { observer } from 'mobx-react-lite'
import { Context } from './index'

const App = observer(() => {
  const { store } = useContext(Context)

  useEffect(() => {
    store.checkAuthGoogle()
  }, [store])

  return (
    <BrowserRouter>
      <AppRouter />
    </BrowserRouter>
  )
})

export default App
