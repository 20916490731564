import React, { useContext, useEffect, useRef } from 'react'
import styles from './joy.module.scss'
import { Context } from 'index'
import { observer } from 'mobx-react-lite'
import { i3d_glob } from '3d/dev2020/i3d_glob'

const Joy = observer(() => {
  const { device } = useContext(Context)
  const canvasRef = useRef()

  var canvas, ctx
  const radius = 30
  // let width = 500 //window.innerWidth
  // let width = 200
  // let height = 100
  let width = device.elementData.width
  let height = device.elementData.height - 20

  //console.log('1 width = ', width, ', height = ', height)
  // let width = 300 //window.innerWidth
  // let height = 150 //radius * 6.5;

  let x_orig = width / 2
  let y_orig = height / 2 // / 3;

  /** * /
  useEffect(() => {
    canvas = canvasRef.current //document.getElementById('canvas')
    //console.log('USE canvas = ', canvas)
    ctx = canvas.getContext('2d')
    resize()

    canvas.addEventListener('mousedown', startDrawing)
    canvas.addEventListener('mouseup', stopDrawing)
    canvas.addEventListener('mousemove', Draw)

    canvas.addEventListener('touchstart', startDrawing)
    canvas.addEventListener('touchend', stopDrawing)
    canvas.addEventListener('touchcancel', stopDrawing)
    canvas.addEventListener('touchmove', Draw)

    window.addEventListener('resize', resize)

    document.getElementById('x_coordinate').innerText = 0
    document.getElementById('y_coordinate').innerText = 0
    document.getElementById('speed').innerText = 0
    document.getElementById('angle').innerText = 0
  }, [])
  /** */
  useEffect(() => {
    window.addEventListener('load', () => {
      width = device.elementData.width
      height = device.elementData.height - 20
      x_orig = width / 2
      y_orig = height / 2 // / 3;
      //console.log('useEffect ==== width = ', width, ', height = ', height)

      // Используя getComputedStyle
      const resizeableDiv = document.getElementById('resizeableDiv')
      const resizeableDivStyles = window.getComputedStyle(resizeableDiv)
      const resizeableDivWidth = resizeableDivStyles.getPropertyValue('width')
      const resizeableDivHeight = resizeableDivStyles.getPropertyValue('height')

      // Используя clientWidth и clientHeight
      // const resizeableDiv = document.getElementById('resizeableDiv');
      // const resizeableDivWidth = resizeableDiv.clientWidth;
      // const resizeableDivHeight = resizeableDiv.clientHeight;

      //console.log({ resizeableDivWidth, resizeableDivHeight })
      canvas = canvasRef.current //document.getElementById('canvas')
      //canvas = document.getElementById('canvas')
      if (!canvas) {
        console.error('NO CANVAS LOADED')
      }
      //console.log('load222 canvas = ', canvas)

      ctx = canvas.getContext('2d')
      resize()

      canvas.addEventListener('mousedown', startDrawing)
      canvas.addEventListener('mouseup', stopDrawing)
      canvas.addEventListener('mousemove', Draw)

      canvas.addEventListener('touchstart', startDrawing)
      canvas.addEventListener('touchend', stopDrawing)
      canvas.addEventListener('touchcancel', stopDrawing)
      canvas.addEventListener('touchmove', Draw)

      window.addEventListener('resize', resize)

      document.getElementById('x_coordinate').innerText = 0
      document.getElementById('y_coordinate').innerText = 0
      document.getElementById('speed').innerText = 0
      document.getElementById('angle').innerText = 0
    })
  }, [])
  // }, [height, width])
  /** * /
  // document.addEventListener('DOMContentLoaded', () => {
  window.addEventListener('load', () => {
    canvas = document.getElementById('canvas')
    //console.log('load canvas = ', canvas)

    if (!canvas) {
      console.error('NO CANVAS LOADED')
    }
    ctx = canvas.getContext('2d')
    resize()

    canvas.addEventListener('mousedown', startDrawing)
    canvas.addEventListener('mouseup', stopDrawing)
    canvas.addEventListener('mousemove', Draw)

    canvas.addEventListener('touchstart', startDrawing)
    canvas.addEventListener('touchend', stopDrawing)
    canvas.addEventListener('touchcancel', stopDrawing)
    canvas.addEventListener('touchmove', Draw)

    window.addEventListener('resize', resize)

    document.getElementById('x_coordinate').innerText = 0
    document.getElementById('y_coordinate').innerText = 0
    document.getElementById('speed').innerText = 0
    document.getElementById('angle').innerText = 0
  })
  /** */

  function resize() {
    ctx.canvas.width = width
    ctx.canvas.height = height
    background()
    //console.log('2 resize ==== width = ', width, ', height = ', height)

    x_orig = width / 2
    y_orig = height / 2

    joystick(x_orig, y_orig)
  }

  function background() {
    ctx.beginPath()
    ctx.arc(x_orig, y_orig, radius + 20, 0, Math.PI * 2, true)
    ctx.fillStyle = '#ECE5E5'
    ctx.fill()
  }

  function joystick(width, height) {
    //console.log('3 width = ', width, ', height = ', height)

    ctx.beginPath()
    ctx.arc(width, height, radius, 0, Math.PI * 2, true)
    ctx.fillStyle = '#F08080'
    ctx.fill()
    ctx.strokeStyle = '#F6ABAB'
    ctx.lineWidth = 8
    ctx.stroke()
  }

  let coord = { x: 0, y: 0 }
  let paint = false

  function getPosition(event) {
    //console.log('getPosition event =', event)
    //console.log('getPosition event.touches =', event.touches)

    //console.log('device.elementData =', { ...device.elementData })
    //console.log('canvas.offsetLeft =', canvas.offsetLeft)

    //console.log('4 width = ', width, ', height = ', height)
    //console.log('4 canvas.width = ', canvas.width, ', canvas.height = ', canvas.height)

    if (event) {
      var mouse_x = event.clientX || event.touches[0].clientX
      var mouse_y = event.clientY || event.touches[0].clientY

      coord.x = mouse_x - device.elementData.left - canvas.offsetLeft
      coord.y = mouse_y - device.elementData.top - canvas.offsetTop
    }
  }

  function is_it_in_the_circle() {
    var current_radius = Math.sqrt(Math.pow(coord.x - x_orig, 2) + Math.pow(coord.y - y_orig, 2))
    if (radius >= current_radius) return true
    else return false
  }

  function startDrawing(event) {
    //console.log('startDrawing event =', event)
    paint = true
    getPosition(event)
    if (is_it_in_the_circle()) {
      //console.log('4 width = ', width, ', height = ', height)
      //console.log('4 canvas.width = ', canvas.width, ', canvas.height = ', canvas.height)

      ctx.clearRect(0, 0, canvas.width, canvas.height)
      background()
      joystick(coord.x, coord.y)
      Draw()
    }
  }

  function stopDrawing() {
    paint = false
    ctx.clearRect(0, 0, canvas.width, canvas.height)
    background()
    joystick(x_orig, y_orig)
    document.getElementById('x_coordinate').innerText = 0
    document.getElementById('y_coordinate').innerText = 0
    document.getElementById('speed').innerText = 0
    document.getElementById('angle').innerText = 0
  }

  function Draw(event) {
    if (paint) {
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      background()
      var angle_in_degrees, x, y, speed
      var angle = Math.atan2(coord.y - y_orig, coord.x - x_orig)

      if (Math.sign(angle) == -1) {
        angle_in_degrees = Math.round((-angle * 180) / Math.PI)
      } else {
        angle_in_degrees = Math.round(360 - (angle * 180) / Math.PI)
      }

      if (is_it_in_the_circle()) {
        joystick(coord.x, coord.y)
        x = coord.x
        y = coord.y
      } else {
        x = radius * Math.cos(angle) + x_orig
        y = radius * Math.sin(angle) + y_orig
        joystick(x, y)
      }

      getPosition(event)

      var speed = Math.round((100 * Math.sqrt(Math.pow(x - x_orig, 2) + Math.pow(y - y_orig, 2))) / radius)

      var x_relative = Math.round(x - x_orig)
      var y_relative = Math.round(y - y_orig)

      //console.log({ x_relative, y_relative, speed, angle_in_degrees })
      if (y_relative > 0) {
        i3d_glob.movingCube.w = true
      }
      document.getElementById('x_coordinate').innerText = x_relative
      document.getElementById('y_coordinate').innerText = y_relative
      document.getElementById('speed').innerText = speed
      document.getElementById('angle').innerText = angle_in_degrees

      //send(x_relative, y_relative, speed, angle_in_degrees);
    }
  }

  return (
    <div>
      {/* <h1>Joystick</h1> */}
      <div className={styles.container}>
        <canvas id="canvas" ref={canvasRef} name="game"></canvas>
        <div className={styles.MOUSEBOT}>
          <div style={{ display: 'block' }}>
            X: <span id="x_coordinate"> </span> Y:
            <span id="y_coordinate"> </span> Speed: <span id="speed"> </span> % Angle:
            <span id="angle"> </span>
          </div>
        </div>
      </div>
    </div>
  )
})

export default Joy
