import React, { createContext } from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import UserStore from './store/UserStore'
import DeviceStore from './store/DeviceStore'
import storeAudio from './store/storeAudio'
import storeVideo from './store/storeVideo'
import storePhoto from './store/storePhoto'
import storeText from './store/storeText'
import storeModel from './store/storeModel'
import Store from './store/store'

import './style.css'

export const Context = createContext()

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <Context.Provider
    value={{
      user: new UserStore(),
      device: new DeviceStore(),
      storeAudio: new storeAudio(),
      storeVideo: new storeVideo(),
      storePhoto: new storePhoto(),
      storeText: new storeText(),
      storeModel: new storeModel(),
      store: new Store(),
    }}
  >
    <App />
  </Context.Provider>
)
