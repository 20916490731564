import { makeAutoObservable } from 'mobx'
import { PROGRESS_TYPES } from 'constants/progressTypes'

export default class StoreText {
  constructor() {
    this._txt = ''
    this._selectedTextId = -1
    this._text = ''
    this._textOne = {}
    this._textPage = 1
    this._textTotal = 0
    this._textLimit = 15
    this._progressPage = PROGRESS_TYPES.WORK
    this._progress = PROGRESS_TYPES.WORK

    makeAutoObservable(this)
  }

  /**** Text ************************************************************************ */
  setSelectedTextId(obj) {
    this._selectedTextId = obj
  }
  get getSelectedTextId() {
    return this._selectedTextId
  }

  setTxt(obj) {
    this._txt = obj // marked text
  }
  get getTxt() {
    return this._txt
  }

  setText(obj) {
    this._text = obj // texts list
  }
  get getText() {
    return this._text
  }

  setTextOne(obj) {
    this._textOne = obj // text record
  }
  get getTextOne() {
    return this._textOne
  }
  setTextPage(page) {
    this._textPage = page
  }
  get getTextPage() {
    return this._textPage
  }
  setTextTotal(total) {
    this._textTotal = total
  }
  get getTextTotal() {
    return this._textTotal
  }
  get getTextLimit() {
    return this._textLimit
  }

  setProgress(obj) {
    this._progress = obj
  }
  get getProgress() {
    return this._progress
  }
  setProgressPage(obj) {
    this._progressPage = obj
  }
  get getProgressPage() {
    return this._progressPage
  }

  /**************************************************************************************** */
}
