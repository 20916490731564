import { makeAutoObservable } from 'mobx'

export default class DeviceStore {
  constructor() {
    // this._isActive = false // for SideBar
    this._isActive = true // for SideBar
    this._currObjY = { y: 0, min: -50, max: 50, step: 0.001, maxObjSize: 1 }

    this._currObjScale = 1
    this._element3d = {}
    this._mobileData = {}
    this._iframeData = {}
    this._animArray = []
    this.loadingProgress = 0
    this.elementData = {
      open: true,
      isDragging: false,
      offsetX: 0,
      offsetY: 0,
      top: 300,
      left: 100,
      width: 200,
      height: 200,
      type: 1,
    }
    this.animMId = { m: -1, id: -1 } // храним номер модели и анимацию, которую будем запускать при первом открытии сцены
    this.tempMaterial = true
    this.showLoader = false
    this.selectByHover = false
    /**************************************************************************************** */

    makeAutoObservable(this)
  }

  //const [animId, setAnimId] = useState(-1)

  setTempMaterial(obj) {
    this.tempMaterial = obj
  }
  setShowLoader(obj) {
    this.showLoader = obj
  }
  setSelectByHover(obj) {
    this.selectByHover = obj
  }

  setAnimMId(obj) {
    this.animMId = obj
  }

  setElementData(obj) {
    //console.log('STORE 111 setElementData obj = ', { ...obj })
    this.elementData = obj
  }
  setLoadingProgress(obj) {
    this.loadingProgress = obj
  }
  setElement3D(obj) {
    this._element3d = obj
  }
  get getElement3D() {
    return this._element3d
  }
  setMobileData(obj) {
    this._mobileData = obj
  }
  get mobileData() {
    return this._mobileData
  }
  setIframeData(obj) {
    this._iframeData = obj
  }
  get iframeData() {
    return this._iframeData
  }

  // get element3d() {
  //   return this._element3d
  // }

  setCurrObjScale(obj) {
    this._currObjScale = obj
  }
  get getCurrObjScale() {
    return this._currObjScale
  }
  setCurrObjY(obj) {
    this._currObjY = obj
  }
  get getCurrObjY() {
    return this._currObjY
  }

  setAnimArray(obj) {
    this._animArray = obj
  }
  get animArray() {
    return this._animArray
  }

  // for SideBar
  setIsActive(bool) {
    this._isActive = bool
  }
  get isActive() {
    return this._isActive
  }

  /**************************************************************************************** */
}
