import { makeAutoObservable } from 'mobx'
import { PROGRESS_TYPES } from 'constants/progressTypes'

export default class StorePhoto {
  constructor() {
    this._photo = []
    this._photoOne = {}
    this._photoPage = 1
    this._photoTotal = 0
    this._photoLimit = 5
    this._progressPage = PROGRESS_TYPES.WORK
    this._progress = PROGRESS_TYPES.WORK

    makeAutoObservable(this)
  }

  /**** Photo ************************************************************************ */
  setPhoto(obj) {
    this._photo = obj
  }
  get getPhoto() {
    return this._photo
  }

  setPhotoOne(obj) {
    this._photoOne = obj
  }
  get getPhotoOne() {
    return this._photoOne
  }
  setPhotoPage(page) {
    this._photoPage = page
  }
  get getPhotoPage() {
    return this._photoPage
  }
  setPhotoTotal(total) {
    this._photoTotal = total
  }
  get getPhotoTotal() {
    return this._photoTotal
  }
  get getPhotoLimit() {
    return this._photoLimit
  }

  setProgress(obj) {
    this._progress = obj
  }
  get getProgress() {
    return this._progress
  }
  setProgressPage(obj) {
    this._progressPage = obj
  }
  get getProgressPage() {
    return this._progressPage
  }

  /**************************************************************************************** */
}
