import React, { useCallback, useEffect, useContext, useRef, useState } from 'react'
import { Context } from 'index'
import styles from './ElementData.module.scss'
import clsx from 'clsx'
import { i3d_glob } from '3d/dev2020/i3d_glob.js'
import { marked } from 'marked'
import stylesUi from 'ui/Ui.module.scss'
import { observer } from 'mobx-react-lite'
import Digit1 from 'ui/Icon/digits/digit1'
import Digit2 from 'ui/Icon/digits/digit2'
import Digit3 from 'ui/Icon/digits/digit3'
import Expand06 from 'ui/Icon/digits/expand06'
import EmptySquare from 'ui/Icon/digits/emptySquare'
// import ArrowsInside from 'ui/Icon/digits/arrowsInside'
import Joy from 'components/sidebar01/joy'

const ElementData = observer(() => {
  const { device } = useContext(Context)
  const { storeText } = useContext(Context)
  const REFResizeableDiv = useRef<HTMLDivElement>(null)
  const REFDraggableDivHeader = useRef<HTMLDivElement>(null)
  const [typeElementWindow, setTypeElementWindow] = useState(1)
  const [expand, setExpand] = useState(false)
  const expandWindow = useRef<boolean>(false)

  const HeaderDownClick = useCallback(
    event => {
      if (expandWindow.current) {
        return
      }
      const clickedElement = event.target
      // Получаем класс элемента
      const elementClass = clickedElement.className
      // Получаем id элемента
      const elementId = clickedElement.id
      // Получаем тип элемента
      const elementType = clickedElement.tagName.toLowerCase()

      if (elementType !== 'svg') {
        i3d_glob.isDragging = true
        const offsetX = event.clientX - device.elementData.left
        const offsetY = event.clientY - device.elementData.top
        device.setElementData({ ...device.elementData, isDragging: true, offsetX, offsetY })
      } else {
        expandWindow.current = true
      }
    },
    [device]
  )

  const MouseMove = useCallback(event => {
    if (i3d_glob.isDragging) {
      // if (device.elementData?.isDragging) {
      const left = event.clientX - device.elementData.offsetX //+ 'px'
      const top = event.clientY - device.elementData.offsetY //+ 'px'

      i3d_glob.device.setElementData({ ...device.elementData, top, left })

      if (REFResizeableDiv.current) {
        REFResizeableDiv.current.style.left = left + 'px'
        REFResizeableDiv.current.style.top = top + 'px'
      }
    }
  }, [])

  const MouseUp = useCallback(
    event => {
      if (expandWindow.current) {
        return
      }
      document.removeEventListener('pointerup', MouseUp)
      if (REFResizeableDiv.current) {
        //console.log('UP 2 ASSIGNE! ')

        try {
          const left = Number(REFResizeableDiv.current.style.left.replace('px', ''))
          const top = Number(REFResizeableDiv.current.style.top.replace('px', ''))
          const width = Number(REFResizeableDiv.current.style.width.replace('px', ''))
          const height = Number(REFResizeableDiv.current.style.height.replace('px', ''))
          // const width = 200
          // const height = 100

          const elData1 = {
            open: device.elementData.open,
            isDragging: device.elementData.isDragging,
            offsetX: device.elementData.offsetX,
            offsetY: device.elementData.offsetY,
            top: top,
            left: left,
            width: width,
            height: height,
            type: device.elementData.type,
          }

          localStorage.setItem('elementData', JSON.stringify(elData1))

          device.setElementData(elData1)
        } catch (err) {
          console.error('err', err)
        }
      }

      i3d_glob.isDragging = false
      //device.setElementData({ ...device.elementData, isDragging: false })

      // isDragging.current = false
      //}
    },
    [device]
  )

  const ResizeableDivClick = useCallback(() => {
    document.addEventListener('pointerup', MouseUp)
  }, [MouseUp])

  const setType = useCallback((type, e) => {
    e.stopPropagation()

    setTypeElementWindow(type)
    localStorage.setItem('elementDataType', type)
  }, [])

  const expandClick = e => {
    e.stopPropagation()

    setExpand(val => {
      return !val
    })

    const padding = 20
    const windowWidth = window.innerWidth
    const windowHeight = window.innerHeight

    if (REFResizeableDiv.current) {
      if (expand) {
        setExpand(false)
        expandWindow.current = false
        REFResizeableDiv.current.style.left = device.elementData.left + 'px'
        REFResizeableDiv.current.style.top = device.elementData.top + 'px'
        REFResizeableDiv.current.style.width = device.elementData.width + 'px'
        REFResizeableDiv.current.style.height = device.elementData.height + 'px'
      } else {
        setExpand(true)
        expandWindow.current = true
        REFResizeableDiv.current.style.left = padding + 'px'
        REFResizeableDiv.current.style.top = padding + 'px'
        REFResizeableDiv.current.style.width = windowWidth - 2 * padding + 'px'
        REFResizeableDiv.current.style.height = windowHeight - 2 * padding + 'px'
      }
    }
  }

  useEffect(() => {
    let elementData
    const elementDataStr = localStorage.getItem('elementData')
    if (elementDataStr) {
      elementData = JSON.parse(elementDataStr)

      device.setElementData(elementData)
    }

    const elementDataType = localStorage.getItem('elementDataType')
    if (elementDataType) {
      setTypeElementWindow(Number(elementDataType))
    }
  }, [device])

  useEffect(() => {
    const REFResizeableDivCurrent = REFResizeableDiv.current
    const REFDraggableDivHeaderCurrent = REFDraggableDivHeader.current

    if (REFResizeableDivCurrent) {
      REFResizeableDivCurrent.addEventListener('pointerdown', ResizeableDivClick)
    }

    if (REFDraggableDivHeader.current) {
      REFDraggableDivHeader.current.addEventListener('pointerdown', HeaderDownClick)
    }

    document.addEventListener('pointermove', MouseMove)

    return () => {
      if (REFDraggableDivHeaderCurrent) {
        REFDraggableDivHeaderCurrent.removeEventListener('pointerdown', HeaderDownClick)
      }
      document.removeEventListener('pointermove', MouseMove)
      document.removeEventListener('pointerup', MouseUp)
    }
  }, [HeaderDownClick, MouseMove, MouseUp, ResizeableDivClick])

  return (
    <div
      id="resizeableDiv"
      ref={REFResizeableDiv}
      className={clsx(styles.resizeable, styles.draggable, {
        [styles.type1]: typeElementWindow === 1,
        [styles.type2]: typeElementWindow === 2,
        [styles.type3]: typeElementWindow === 3,
      })}
      style={{
        width: device.elementData.width + 'px',
        height: device.elementData.height + 'px',
        top: device.elementData.top + 'px',
        left: device.elementData.left + 'px',
      }}
    >
      <div
        ref={REFDraggableDivHeader}
        className={clsx(styles.draggableDivHeader, {
          [styles.type1Header]: typeElementWindow === 1,
          [styles.type2Header]: typeElementWindow === 2,
          [styles.type3Header]: typeElementWindow === 3,
        })}
      >
        <div className={styles.headerText}>
          {/* {device.getElement3D?.elementName ? <>{storeText.getTextOne.name}</> : <span>Select model element</span>} */}
        </div>
        <div className={styles.headerButtons} onClick={event => event.stopPropagation()}>
          {/* <span className={styles.headerOneButton} onClick={e => setType(1, e)}>
            <Digit1 fill={'#4e78f6'} width="18" height="18" />
          </span>
          <span className={styles.headerOneButton} onClick={e => setType(2, e)}>
            <Digit2 fill={'#4e78f6'} width="18" height="18" />
          </span>
          <span className={styles.headerOneButton} onClick={e => setType(3, e)}>
            <Digit3 fill={'#4e78f6'} width="18" height="18" />
          </span>

          {expand ? (
            <span className={styles.headerOneButton} onClick={e => expandClick(e)}>
              <EmptySquare fill={'#fff'} width="18" height="18" />
            </span>
          ) : (
            <span className={styles.headerOneButton} onClick={e => expandClick(e)}>
              <Expand06 fill={'#fff'} width="18" height="18" />
            </span>
          )} */}
        </div>
      </div>
      <div
        id="draggableDivContent"
        className={clsx(styles.draggableDivContent, {
          [styles.type1Content]: typeElementWindow === 1,
          [styles.type2Content]: typeElementWindow === 2,
          [styles.type3Content]: typeElementWindow === 3,
        })}
      >
        {/* {device.getElement3D?.elementName ? (
          <>
            <div className={stylesUi.marked} dangerouslySetInnerHTML={{ __html: marked.parse(storeText.getTxt) }}></div>
          </>
        ) : (
          <h3></h3>
        )} */}
        <Joy />
      </div>

      <div id="resizeDiv" className={clsx(styles.resizeHandle, styles.bottomRight)}></div>
    </div>
  )
})

export default ElementData
