import React from 'react'

import {
  MODEL_ROUTE,
  MODEL_ROUTE_3D,
  MODEL_ROUTE_3D_CLEAR,
  MODEL_ROUTE_3D_CLEAR_TEXT,
  PHOTO_ROUTE,
  VIDEO_ROUTE,
  AUDIO_ROUTE,
  LOGIN_ROUTE,
  REGISTRATION_ROUTE,
  HOME_ROUTE,
  // SET_ROUTE,
  // SET_ROUTE_3D,
  // SET_ROUTE_3D_M,
  // SET_ROUTE_3D_CLEAR,
  TEXT_ROUTE,
  PRIVACY_ROUTE,
  TOS_ROUTE,
  TOS_COOKIE_ROUTE,
} from './utils/consts'

const Auth = React.lazy(() => import('./pages/Auth/Auth'))
const ModelType3d = React.lazy(() => import('./pages/ModelType3d/ModelType3d'))
const ModelType3dPage = React.lazy(() => import('./pages/ModelType3d/ModelPage'))
const Model3d = React.lazy(() => import('./pages/ModelType3d/Model3d'))
// const Model3dClear = React.lazy(() => import('./pages/ModelType3d/Model3dClear'))

const Home = React.lazy(() => import('./pages/Home'))

const Video = React.lazy(() => import('./pages/Video/Video'))
const VideoPage = React.lazy(() => import('./pages/Video/VideoPage'))

const Photo = React.lazy(() => import('./pages/Photo/Photo'))
const PhotoPage = React.lazy(() => import('./pages/Photo/PhotoPage'))

const Audio = React.lazy(() => import('./pages/Audio/Audio'))
const AudioPage = React.lazy(() => import('./pages/Audio/AudioPage'))

const Text = React.lazy(() => import('./pages/Text/Text'))
const TextPage = React.lazy(() => import('./pages/Text/TextPage'))
const Privacy = React.lazy(() => import('./pages/Static/Privacy'))
const Tos = React.lazy(() => import('./pages/Static/Tos'))
const Tos2 = React.lazy(() => import('./pages/Static/Tos2'))
const TosCookie = React.lazy(() => import('./pages/Static/TosCookie'))

export const authRoutes = []

export const publicRoutes = [
  { path: MODEL_ROUTE, Component: ModelType3d },
  { path: MODEL_ROUTE_3D, Component: ModelType3d },
  { path: MODEL_ROUTE + '/:id', Component: ModelType3dPage },
  { path: MODEL_ROUTE_3D + '/:id', Component: Model3d },
  { path: MODEL_ROUTE_3D_CLEAR + '/:id', Component: Model3d },
  { path: MODEL_ROUTE_3D_CLEAR_TEXT + '/:id', Component: Model3d },

  { path: LOGIN_ROUTE, Component: Auth },
  { path: REGISTRATION_ROUTE, Component: Auth },

  { path: PHOTO_ROUTE, Component: Photo },
  { path: PHOTO_ROUTE + '/:id', Component: PhotoPage },
  { path: VIDEO_ROUTE, Component: Video },
  { path: VIDEO_ROUTE + '/:id', Component: VideoPage },
  { path: AUDIO_ROUTE, Component: Audio },
  { path: AUDIO_ROUTE + '/:id', Component: AudioPage },

  { path: TEXT_ROUTE, Component: Text },
  { path: TEXT_ROUTE + '/:id', Component: TextPage },

  { path: PRIVACY_ROUTE, Component: Privacy },
  // { path: TOS_ROUTE, Component: Tos },
  { path: TOS_ROUTE, Component: Tos2 },
  { path: TOS_COOKIE_ROUTE, Component: TosCookie },

  { path: HOME_ROUTE, Component: Home },
]
