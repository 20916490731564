import { makeAutoObservable } from 'mobx'
import { PROGRESS_TYPES } from 'constants/progressTypes'

export default class StoreModel {
  constructor() {
    this._model = []
    this._model3d = []
    this._modelOne = {}
    this._element3D = {}
    this._modelPage = 1
    this._modelTotal = 0
    this._modelLimit = 15
    this._progressPage = PROGRESS_TYPES.WORK
    this._progress = PROGRESS_TYPES.WORK
    this._progress3d = PROGRESS_TYPES.SUCCESS
    this._setOne = {}

    makeAutoObservable(this)
  }

  /**** Model ************************************************************************ */
  setModel(obj) {
    this._model = obj
  }
  get getModel() {
    return this._model
  }
  setProgress3d(obj) {
    this._progress3d = obj
  }
  get getProgress3d() {
    return this._progress3d
  }
  setElement3D(obj) {
    this._element3D = obj
  }
  get getElement3D() {
    return this._element3D
  }
  setSetOne(obj) {
    this._setOne = obj
  }
  get getSetOne() {
    return this._setOne
  }

  setModelOne(obj) {
    this._modelOne = obj
  }
  get getModelOne() {
    return this._modelOne
  }
  setModelPage(page) {
    this._modelPage = page
  }
  get getModelPage() {
    return this._modelPage
  }
  setModelTotal(total) {
    this._modelTotal = total
  }
  get getModelTotal() {
    return this._modelTotal
  }
  get getModelLimit() {
    return this._modelLimit
  }

  setProgress(obj) {
    this._progress = obj
  }
  get getProgress() {
    return this._progress
  }
  setProgressPage(obj) {
    this._progressPage = obj
  }
  get getProgressPage() {
    return this._progressPage
  }

  /**************************************************************************************** */
}
