import { $host } from '../../http'

export default class AuthService {
  static async login(email, password) {
    return $host.post('/api/auth/login', { email, password })
  }

  // static async loginGoogle(email, password) {
  //   return $host.get('/api/auth/google')
  // }

  static async registration(email, password) {
    return $host.post('/api/auth/registration', { email, password })
  }

  static async logout() {
    return $host.post('/api/auth/logout')
  }
}
