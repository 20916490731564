const vertexShader = `
varying vec2 vUv;
void main()	{
  vUv = uv;
  gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);
}`

const fragmentShader = `
uniform float SCREEN_WIDTH;
uniform float SCREEN_HEIGHT;
uniform vec2 resolution;
uniform float iTime;
varying vec2 vUv; // текстурные координаты

vec3 palette( float t ) {
  vec3 a = vec3(0.5, 0.5, 0.5);
  vec3 b = vec3(0.5, 0.5, 0.5);
  vec3 c = vec3(1.0, 1.0, 1.0);
  vec3 d = vec3(0.263,0.416,0.557);

  return a + b*cos( 6.28318*(c*t+d) );
}


void main() {
  vec2 uv = vec2(1.0, 1.0);
  uv.x = (gl_FragCoord.x * 2.0 - SCREEN_WIDTH) / SCREEN_HEIGHT;
  uv.y = (gl_FragCoord.y * 2.0 - SCREEN_HEIGHT) / SCREEN_HEIGHT;

  vec2 uv0 = uv;
  vec3 finalColor = vec3(0.0);
  
  for (float i = 0.0; i < 4.0; i++) {
      uv = fract(uv * 1.5) - 0.5;

      float d = length(uv) * exp(-length(uv0));

      vec3 col = palette(length(uv0) + i*.4 + iTime*.4);

      d = sin(d*8. + iTime)/8.;
      d = abs(d);

      d = pow(0.01 / d, 1.2);

      finalColor += col * d;
  }
      
  gl_FragColor = vec4(finalColor, 1.0);

  //gl_FragColor = vec4(0.50, 0.40, 0.90, 1.0);
}`

export default { vertexShader, fragmentShader }
